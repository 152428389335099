import { Injectable, InjectionToken, inject } from '@angular/core'
import { map, of } from 'rxjs'
import { DimensionLevelService } from 'src/app/core/ngrx-store/entity-services'
import { DimensionLevel } from 'src/app/core/ngrx-store/models'
import {
	EntityService,
	EntityType,
	SidebarItem,
	entitySelectorFactory,
} from '../../models'
import { DimensionLevelCreateNewContentComponent } from './dimension-level-create-new.component'
import { dimensionLevelSelector } from 'src/app/core/ngrx-store/dimension-level/dimension-level.selectors'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'

export const selectDimensionLevelEntity = entitySelectorFactory(
	dimensionLevelSelector
)

const levelNumberOptions = Array.from({ length: 12 }, (_, i) => ({
	value: i + 1,
	label: (i + 1).toString(),
}))

@Injectable({ providedIn: 'root' })
export class DimensionLevelEntityService extends EntityService<DimensionLevel> {
	constructor(private dimensionLevelService: DimensionLevelService) {
		super(dimensionLevelService, selectDimensionLevelEntity)

		this.sidebarItems$ = this.entityCollectionService.filteredEntities$.pipe(
			map((entities) => [
				{
					// This is the static root node of the sidebar tree, containing just the title
					totalCount: this.getTotalCount(entities),
					id: '',
					title: of(dimensionLevelEntityType.title),
					// Children contain the top-level entities and so on
					children: entities
						.filter((level) => !level.parentId)
						.map((level) => this.buildSidebarTree(level, entities)),
				},
			])
		)
		this.sidebarView = 'tree'
		this.formlyFields = [
			{
				fieldGroupClassName: 'grid align-items-end',
				fieldGroup: [
					{
						key: 'description',
						type: 'input',
						className: 'g-col-12',
						props: {
							label: $localize`Nimi`,
						},
						expressions: {
							'props.disabled': (field: FormlyFieldConfig) =>
								field.model.disabled,
						},
					},
					{
						key: 'orderNumber',
						type: 'select',
						className: 'g-col-12',
						props: {
							label: $localize`Järjestysnumero`,
						},
						expressions: {
							'props.disabled': (field: FormlyFieldConfig) =>
								field.model.disabled,
							'props.options': (field: FormlyFieldConfig) =>
								this.dimensionLevelService.entities$.pipe(
									map((dimensionLevels: DimensionLevel[]) => {
										const hasOrderNumber12 = dimensionLevels.some(
											(level) => level.orderNumber === 12
										)
										if (hasOrderNumber12 && field.model.orderNumber !== 12) {
											return levelNumberOptions.filter(
												(option) => option.value !== 12
											)
										}
										return levelNumberOptions
									})
								),
						},
					},
					{
						key: 'disabled',
						type: 'checkbox',
						className: 'g-col-12',
						props: {
							label: $localize`Ei käytössä`,
						},
					},
				],
			},
		]
	}

	override mapEntityToSidebarItem = (dimensionLevel: DimensionLevel) => {
		return {
			id: dimensionLevel.id.toString(),
			title: of(dimensionLevel.description),
		}
	}

	/**
	 * Builds a hierarchical nested sidebar object from a list of items
	 * @param dimensionLevel The root item
	 * @param entities The list of items
	 * @returns The nested sidebar item
	 */
	private buildSidebarTree(
		dimensionLevel: DimensionLevel,
		entities: DimensionLevel[]
	): SidebarItem {
		const children = entities.filter(
			(child) => child.parentId === dimensionLevel.id
		)

		return {
			...this.mapEntityToSidebarItem(dimensionLevel),
			children: children.map((child) => this.buildSidebarTree(child, entities)),
		}
	}

	/**
	 * Calculates the total count of enabled entities.
	 *
	 * @param entities - The array of entities.
	 * @returns The total count of entities.
	 */
	private getTotalCount(entities: DimensionLevel[]): number {
		const enabledEntities = entities.filter(
			(entity) => entity.disabled === false
		)
		return enabledEntities.length
	}
}

export const dimensionLevelEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Dimension,
	title: $localize`Kohdetasot`,
	explanation: $localize`Kohdetasoja käytetään kirjausten kohdistamiseen
	Yksittäiselle kohdetasolle luodaan kohteet
	Valitse sivupalkista tarkasteltava tai muokattava kohdetaso`,
	path: 'dimension-levels',
	hideDeleteButton: true,
	serviceToken: new InjectionToken<DimensionLevelEntityService>(
		'dimension-levels',
		{
			factory: () => inject(DimensionLevelEntityService),
		}
	),
	createNewContentComponent: DimensionLevelCreateNewContentComponent,
}
